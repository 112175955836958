import React from "react";
import _ from "lodash";

import { Layout } from "../components/index";
import { htmlToReact, safePrefix } from "../utils";
import Helmet from "react-helmet";
import CtaBooksy from '../components/CtaBooksy'

export default class Post extends React.Component {
  render() {
    return (
      <Layout {...this.props}>
        <Helmet>
          <title>
            {_.get(this.props, "pageContext.frontmatter.title_seo") &&
              _.get(this.props, "pageContext.frontmatter.title_seo") + " - "}
            {_.get(this.props, "pageContext.site.siteMetadata.title")}
          </title>
        </Helmet>
        <div className="outer">
          <div className="inner-medium">
            <article className="post post-full">
              <header className="post-header">
                <h1 className="post-title">
                  {_.get(this.props, "pageContext.frontmatter.title_seo")}
                </h1>
              </header>
              {_.get(
                this.props,
                "pageContext.frontmatter.content_img_path"
              ) && (
                <div className="post-thumbnail">
                  <img
                    src={safePrefix(
                      _.get(
                        this.props,
                        "pageContext.frontmatter.content_img_path"
                      )
                    )}
                    alt={`${_.get(
                      this.props,
                      "pageContext.frontmatter.title_seo"
                    )}`}
                  />
                </div>
              )}

              <div className="post-subtitle">
                {_.get(this.props, "pageContext.frontmatter.title") && (
                  <>
                    {htmlToReact(
                      _.get(this.props, "pageContext.frontmatter.title")
                    )}{" "}
                    <br />
                  </>
                )}
                {_.get(this.props, "pageContext.frontmatter.price") ? (
                  <div>
                    <strong>Cena:</strong> {_.get(this.props, "pageContext.frontmatter.price")}
                  </div>
                ) : null}
                {_.get(this.props, "pageContext.frontmatter.duration") ? (
                  <div>
                    <strong>Czas trwania:</strong> {_.get(this.props, "pageContext.frontmatter.duration")}
                  </div>
                ) : null}
              </div>
              <div className="post-content">
                {htmlToReact(_.get(this.props, "pageContext.html"))}
              </div>
              <div>
                Kategoria: {htmlToReact(
                  _.get(this.props, "pageContext.frontmatter.category")
                )}
              </div>
            </article>

          </div>
        </div>
        <CtaBooksy />
      </Layout>
    );
  }
}
